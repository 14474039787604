import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import News from './News';
import BecomeBreeder from './BecomeBreeder';
import Births from './Births';
import Contact from './Contact';
import Dogs from './Dogs';

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <Routes>
          <Route path="/" element={<News />} />
          <Route path="/nos-chiens" element={<Dogs />} />
          <Route path="/devenir-famille" element={<BecomeBreeder />} />
          <Route path="/carnet-de-naissances" element={<Births />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
