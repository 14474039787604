import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";


import './BecomeBreeder.css';

const BecomeBreeder = () => {
  return (
    <div className="container d-flex flex-column">
      <Helmet>
        <title>Devenir famille d'élevage de golden retriever</title>
        <meta
          name="description"
          content="Nous proposons un service de famille d'élevage de golden retriever dans le Puy-de-dôme. C'est une alternative à l'achat d'un chiot et permet à une chienne de vivre une vie de famille tout en faisant des portées."
        />      
      </Helmet>
      <h1 className='mb-3'>Devenez famille d'élevage !</h1>
      <p className="fw-bold article">Qu'est-ce qu'une famille d'élevage </p>
      <p className='article'>C'est un foyer qui accueille une de nos chiennes d'élevage pour lui offrir toute l'attention dont peut bénéficier un chien de famille.</p>
      <p className="fw-bold article">Quel intérêt ?</p>
      <p className='article'>L'intérêt est triple, puisqu'il doit satisfaire tout le monde, la famille d'accueil, nous (sa famille d'origine) et avant tout, les chiens concernés.</p>
      <p className='article'>Pour la famille d'accueil, elle reçoit gratuitement une chienne de grande qualité, éduquée, dressée, avec laquelle elle pourra, plus qu'honorablement, participer à des épreuves de travail ou des expositions de beauté ou simplement vivre au quotidien avec un chien passionnant. A l'âge de la retraite (8 ans) le chien termine son existence paisiblement dans sa famille d'adoption.</p>
      <p className='article'>Pour nous, les contraintes liées à la gestion d'un nombre important de chiens sont limitées par ce principe qui nous permet de nous consacrer aux plus jeunes, ceux qui monopolisent le plus de temps. Nous n'avons pas non plus à faire des démarches difficiles pour trouver une famille pour nos retraitées.</p>
      <p className='article'>Enfin, pour les chiennes de notre élevage l'avantage est évident, tant il est reconnu que le golden n'est pas le chien qui supporte le mieux la vie en chenil. Et surtout, le golden est un chien très narcissique, qui n'aime rien tant qu'être le centre d'intérêt d'une famille, plutôt qu'un chien dans une meute.</p>
      <p className='article'>C'est un système qui fonctionne très bien si il contente tout le monde, nous avons commencé à le mettre en forme avec Payanne puis Salem. Il s'appuie sur un contrat que nous rédigeons conjointement, pour énoncer les responsabilités et obligations mutuelles, en fonction des desideratas des postulants et de nos disponibilités. Evidemment, cette possibilité n'est offerte qu'à des gens proches géographiquement.</p>
      <p className='article'>Si vous êtes intéressés, n'hésitez pas à nous contacter.</p>
    </div>
  );
};

export default BecomeBreeder;
