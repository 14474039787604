import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import * as pdfjs from 'pdfjs-dist';

import './PDFViewer.css';

const PDFViewer = ({ url }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setPageNumber(1);
    };

    return (
        <div className='pdf-container'>
            <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                <Page renderAnnotationLayer={false} renderTextLayer={false} scale={1.7} pageNumber={pageNumber} />
            </Document>
        </div>
    );
};

export default PDFViewer;