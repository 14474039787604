import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";

import CarouselGolden from './CarouselGolden';

import video from './video/naisssance_Scapa2.mp4';
import video2 from './video/naisssance_Scapa3.mp4';

import './Births.css'

const images = require.context('./img/golden/naissance', true);
const imageList = images.keys().filter(image => image.includes("naissance")).map(image => images(image));

const Births = () => {
  return (
    <div className="container">
      <Helmet>
        <title>Dernières naissances de chiots golden retriever</title>
        <meta
          name="description"
          content="Retrouvez l'actualité des dernières naissances de chiots golden retriever dans le Puy-de-dôme de l'élevage au travers de photos et de vidéos."
        />      
      </Helmet>
      <h1 className='mb-3'>Carnet de naissances</h1>
      <div className='d-flex justify-content-center mb-3 video-container'>
        <video width="auto" height="auto" autoPlay loop muted playsInline>
          <source src={video} type="video/mp4"/>
        </video>
      </div>
      <div className='d-flex justify-content-center mb-3 video-container'>
        <video width="auto" height="auto" autoPlay loop muted playsInline>
          <source src={video2} type="video/mp4"/>
        </video>
      </div>
      <div className='d-flex justify-content-center'>
          <p>Vidéos de la portée de 12 chiots de <Link to='/nos-chiens' state={{name:'Scapa'}}>Scapa</Link></p>
      </div>
      <CarouselGolden imageList={imageList}/>
    </div>
  );
};

export default Births;
