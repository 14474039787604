import React from 'react';
import { Link } from 'react-router-dom';
/*import { FacebookProvider, Page } from 'react-facebook';*/
import CarouselGolden from './CarouselGolden';
import { Helmet } from "react-helmet";


const images = require.context('./img/golden/Présentation', true);
const imageList = images.keys().map(image => images(image));

const News = () => {
  return (
    <div className="container">
      <Helmet>
        <title>Présentation de l'élevage de golden retriever</title>
        <meta
          name="description"
          content="Bienvenue à l'élevage des Bruyères de Servières à Chateauneuf-les-bains dans le Puy de dôme (63) en Auvergne Rhône Alpes. Nous sommes un élevage de golden retriever LOF proposant également un service de famille d'élevage de chiots"
        />      
      </Helmet>
      <h1 className='mb-3'>Présentation</h1>
      <div className='presentation'>
        <p>
          C’est au cœur des volcans d’Auvergne qu’est né notre élevage de golden retrievers il y a plus de 25 ans. Des bords du lac de Servières qui a donné son nom à notre élevage à Châteauneuf (Puy-de-Dôme) qui domine la vallée de La Sioule notre lignée s’est affirmée dans la pure tradition du golden retriever originel. Notre très stricte sélection de reproducteurs issus de lignées dites de travail nous permet de proposer des chiots qui deviendront les meilleurs compagnons de vie, ainsi que de formidables assistants de personnes non-voyantes, ou bien sûr, d’excellents chiens de chasse.
        </p>
        <p>
          Notre amour pour cette race nous a donné envie de la partager tout en restant des amateurs, au sens premier du terme, qui vivent avec leurs chiens, tout en offrant le sérieux des professionnels que nous sommes. C’est pourquoi nous ne donnons naissance chaque année qu’à deux ou trois portées pour garder le plaisir et l’émerveillement. Dans la même logique nous n’avons que quatre à cinq chiens adultes présents à l’élevage pour qu’ils ne vivent pas en chenil.
        </p>
        <p>
          Tous nos chiots sont LOF, vaccinés, vermifugés et identifiés par puce électronique. Les parents sont tous contrôlés pour les tares et maladies réputées héréditaires : tares oculaires, dysplasies des hanches et des coudes ainsi que le panel des tests génétiques liés à la race recommandé par la SCC.
        </p>
        <p>
          Les chiots partent à 8 semaines pour une meilleure adaptation à leur nouvelle vie.
        </p>
        <p>
          Nous recevons sur rendez-vous la semaine et le week-end.
        </p>
      </div>
      <div>
        <CarouselGolden imageList={imageList}/>
      </div>
      {/* <h1 className='mb-3'>News</h1>
      <FacebookProvider appId="">
        <Page href="https://www.facebook.com/people/%C3%89levage-des-Bruy%C3%A8res-de-Servi%C3%A8re/100057480758331/" tabs="timeline" />
      </FacebookProvider> */}
    </div>
  );
};

export default News;
