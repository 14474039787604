import React from 'react';
import { Helmet } from "react-helmet";

import "./Contact.css"

const Contact = () => {
    const handleMailClick = () => {
        window.location.href = "mailto:philippe.laronde@sfr.fr";
    };

    return (
        <div className="container d-flex justify-content-center align-items-center flex-column">
            <Helmet>
                <title>Contacter l'élevage de golden retriever</title>
                <meta
                name="description"
                content="Nos coordonnées de contact pour avoir des informations sur l'élevage des Bruyères de Servière dans le Puy-de-dôme, ou pour réserver un chiot LOF"
                />
            </Helmet>
            <h1 className='mb-3'>Contact</h1>
            <p className='fw-bold'>Natalie et Philippe Laronde</p>
            <p>Le Château</p>
            <p>Lieu-dit Les Boulons</p>
            <p>63390 Châteauneuf les Bains</p>
            <p>tél : 0612227354</p>
            <p>tél : 0603346008</p>
            <p>
                mail :&nbsp;
                <label onClick={handleMailClick} className='link-primary'>philippe.laronde@sfr.fr</label>
            </p>
        </div>
    );
};

export default Contact;
