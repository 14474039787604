import React from 'react';
import CarouselGolden from './CarouselGolden';
import PDFViewer from './PDFViewer';

function Dog({dog}) {

    const images = require.context('./img/golden/', true);
    const imageList = images.keys().filter(image => image.includes(dog)).map(image => images(image));

    const pdf = 'pdf/'+dog+'.pdf'

    var link = ""

    switch(dog){
        case "Scapa":
            link = "https://www.centrale-canine.fr/lofselect/chien/scapa-des-bruyeres-de-serviere-7792263";
            break;
        case "Laphroaig":
            link = "https://www.centrale-canine.fr/lofselect/chien/laphroaig-des-bruyeres-de-serviere-6335650";
            break;
        case "Peat":
            link = "https://www.centrale-canine.fr/lofselect/chien/peat-des-bruyeres-de-serviere-7362588"
            break;
        case "Salou":
            link ="https://www.centrale-canine.fr/lofselect/chien/salou-des-bruyeres-de-serviere-7792272"
            break;
        case "Speyburn":
            link = "https://www.centrale-canine.fr/lofselect/chien/speyburn-des-bruyeres-de-serviere-8008002"
            break;
    }

    return (
        <div className='container'>
            <h1 className='mb-3'>{dog} des Bruyères de Servière</h1>
            <PDFViewer url={pdf}/>
            <p>
                Retrouver son pedigree complet sur <a href={link}>la centrale canine</a>
            </p>
            <CarouselGolden imageList={imageList}/>
        </div>
    );

};

export default Dog;
